<template>
    <div
        class="Dropdown"
        ref="dropdown"
        v-click-outside="closeDropdown"
        :class="[
            { 'is-small': size === 'small' },
            { 'is-open': dropdownIsOpen },
            { 'is-single-column': items.length < 10 },
        ]"
        @click="toggleDropdown"
        @keydown.esc="closeDropdown"
        @keydown.down.prevent="focusNextItem"
        @keydown.up.prevent="focusPrevItem"
        @keydown.enter.prevent="selectFocusedItem"
        tabindex="0"
        role="combobox"
        aria-haspopup="listbox"
        :aria-expanded="dropdownIsOpen ? 'true' : 'false'"
        :aria-controls="dropdownId"
    >
        <div>
            <span class="Selected" :class="{ 'not-selected': !internalSelected }">
                {{ getItemLabel(internalSelected || placeholder || items[0]) }}
            </span>

            <IconCross
                v-if="(selected || internalSelected) && hasRemoveIcon"
                @click.native.stop="handleClear"
                class="Icon--Cross"
                aria-label="Clear selection"
                role="button"
                tabindex="0"
                @keydown.enter.prevent="handleClear"
            />
            <div class="Dropdown-Wrapper">
                <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" v-bind:svg-inline="''" v-bind:fill="'transparent'" v-bind:stroke-width="'3'" v-bind:stroke="'#555'" v-bind:height="'19'" v-bind:width="'19'" v-bind:class="'Icon-Dropdown'" v-bind:ref="'dropdownIcon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd" stroke="currentColor"><path stroke-width="3" d="M.5.5v18h18V.5H.5z"/><path d="M5 8l4.523 4L14 8.04"/></g></svg>

                <portal to="dropdown">
                    <div
                        class="Dropdown-Overlay"
                        :class="{ 'is-open': dropdownIsOpen }"
                        @click="closeDropdown"
                    ></div>
                    <div
                        v-if="dropdownIsOpen"
                        class="Dropdown-Items"
                        ref="dropdownItems"
                        :class="{ 'is-open': dropdownIsOpen, 'is-left': dropdownIsLeft }"
                        :style="portalStyles()"
                        :id="dropdownId"
                        role="listbox"
                        aria-labelledby="dropdown-label"
                    >
                        <div v-if="hasSearch" class="Search">
                            <Input
                                placeholder="Suchen"
                                keyName="query"
                                @onKeyPress="handleSearch"
                                ref="searchInput"
                            />
                        </div>
                        <div class="Dropdown-Items-Scroll-Wrapper" :style="dropdownStyle">
                            <span
                                v-for="(item, i) in filteredItems"
                                class="Dropdown-Item"
                                :class="[
                                    {
                                        'is-selected': isItemSelected(item),
                                        'is-focused': focusedIndex === i,
                                    },
                                ]"
                                :key="i"
                                @click="handleItemClick(item)"
                                @mouseover="focusedIndex = i"
                                role="option"
                                :aria-selected="isItemSelected(item) ? 'true' : 'false'"
                                :tabindex="focusedIndex === i ? 0 : -1"
                                @keydown.enter.prevent="handleItemClick(item)"
                            >
                                {{ getItemLabel(item) }}
                            </span>
                        </div>
                    </div>
                </portal>
            </div>
        </div>
    </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import IconCross from '@/components/icons/IconCross';
import Fuse from 'fuse.js';
import Input from '@/components/widgets/Input';

export default {
    components: {
        Input,
        IconCross,
    },
    props: {
        hasRemoveIcon: {
            type: Boolean,
            default: true,
        },
        hasSearch: {
            type: Boolean,
            default: false,
        },
        variant: {
            type: String,
            default: 'default',
        },
        selected: {
            type: [String, Number, Boolean, Object],
        },
        dropdownHeight: [String, Number],
        items: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: 'Select an option',
        },
        size: {
            type: String,
            default: 'small',
        },
    },
    data() {
        return {
            filteredItems: [],
            dropdownStyle: {},
            dropdownIsOpen: false,
            dropdownIsLeft: false,
            internalSelected: null,
            focusedIndex: -1,
            dropdownId: `dropdown-${Math.random()
                .toString(36)
                .substring(2, 9)}`,
        };
    },
    directives: {
        ClickOutside,
    },
    watch: {
        selected(newValue) {
            if (newValue !== this.internalSelected) {
                this.internalSelected = newValue;
            }
        },
        dropdownIsOpen(val) {
            if (val) {
                this.filteredItems = this.items;
                this.focusedIndex = this.getSelectedItemIndex();

                this.$nextTick(() => {
                    this.calculateDropdownWidth();
                    if (this.hasSearch && this.$refs.searchInput) {
                        this.$refs.searchInput.$el.querySelector('input').focus();
                    } else if (this.$refs.dropdownItems) {
                        const selectedItem = this.$refs.dropdownItems.querySelector('.is-selected');
                        if (selectedItem) {
                            selectedItem.focus();
                            this.scrollToItem(selectedItem);
                        }
                    }
                });

                // Add event listeners for keyboard navigation
                document.addEventListener('keydown', this.handleKeyDown);
            } else {
                // Remove event listeners when dropdown is closed
                document.removeEventListener('keydown', this.handleKeyDown);
            }
        },
        items(val) {
            this.filteredItems = val;
        },
    },
    methods: {
        isItemSelected(item) {
            return (
                this.getItemLabel(item) === this.getItemLabel(this.internalSelected) ||
                this.getItemLabel(item) === this.getItemLabel(this.selected)
            );
        },
        getSelectedItemIndex() {
            if (!this.internalSelected && !this.selected) return -1;

            return this.filteredItems.findIndex(
                item =>
                    this.getItemLabel(item) === this.getItemLabel(this.internalSelected) ||
                    this.getItemLabel(item) === this.getItemLabel(this.selected),
            );
        },
        focusNextItem() {
            if (!this.dropdownIsOpen) {
                this.toggleDropdown();
                return;
            }

            if (this.focusedIndex < this.filteredItems.length - 1) {
                this.focusedIndex++;
                this.scrollToFocusedItem();
            }
        },
        focusPrevItem() {
            if (!this.dropdownIsOpen) {
                this.toggleDropdown();
                return;
            }

            if (this.focusedIndex > 0) {
                this.focusedIndex--;
                this.scrollToFocusedItem();
            }
        },
        scrollToFocusedItem() {
            this.$nextTick(() => {
                const items = this.$refs.dropdownItems?.querySelectorAll('.Dropdown-Item');
                if (items && items[this.focusedIndex]) {
                    this.scrollToItem(items[this.focusedIndex]);
                }
            });
        },
        scrollToItem(element) {
            if (!element) return;

            const container = this.$refs.dropdownItems.querySelector(
                '.Dropdown-Items-Scroll-Wrapper',
            );
            if (!container) return;

            const containerRect = container.getBoundingClientRect();
            const elementRect = element.getBoundingClientRect();

            if (elementRect.bottom > containerRect.bottom) {
                container.scrollTop += elementRect.bottom - containerRect.bottom;
            } else if (elementRect.top < containerRect.top) {
                container.scrollTop -= containerRect.top - elementRect.top;
            }
        },
        selectFocusedItem() {
            if (this.focusedIndex >= 0 && this.focusedIndex < this.filteredItems.length) {
                this.handleItemClick(this.filteredItems[this.focusedIndex]);
            }
        },
        handleKeyDown(e) {
            // Prevent default behavior for arrow keys to avoid page scrolling
            if (['ArrowDown', 'ArrowUp', 'Enter', 'Escape'].includes(e.key)) {
                e.preventDefault();
            }
        },
        handleSearch({ query }) {
            if (query.length === 0) {
                this.filteredItems = this.items;
                this.focusedIndex = -1;
                return;
            }

            const options = {
                includeScore: true,
                threshold: 0.4,
                keys: ['value', 'label'],
            };

            const fuse = new Fuse(this.items, options);
            const result = fuse.search(query);

            if (result.length > 0) {
                const [topMatch] = result;
                if (topMatch.score <= 0.1) {
                    this.filteredItems = [topMatch.item];
                    this.focusedIndex = 0;
                    return;
                }
            }

            this.filteredItems = result.map(r => r.item);
            this.focusedIndex = this.filteredItems.length > 0 ? 0 : -1;
        },
        getItemLabel(item) {
            if (!item) return '';
            return item.label || item.value || item;
        },
        async calculateDropdownWidth() {
            const columnCount = this.variant === 'single-column' ? 1 : 3;
            await this.setColumnCount(columnCount);

            let { dropdownIcon, dropdownItems } = this.$refs;
            if (!dropdownIcon || !dropdownItems) return;

            this.dropdownItemsWidth = dropdownItems.offsetWidth;
            let { left, top } = dropdownIcon.getBoundingClientRect() || {};

            let isRight = left + this.dropdownItemsWidth > window.innerWidth;
            for (let i = 5; i > 0 && isRight; i--) {
                this.setColumnCount(i);
                let { left } = dropdownIcon.getBoundingClientRect() || {};
                isRight = left + this.dropdownItemsWidth > window.innerWidth;
            }
        },
        portalStyles() {
            const { dropdownIcon } = this.$refs;
            if (!dropdownIcon) return {};

            const { left, top } = dropdownIcon.getBoundingClientRect() || {};
            return {
                position: 'fixed',
                left: `${left}px`,
                top: `${20 + top}px`,
                zIndex: 10000,
            };
        },
        toggleDropdown(e) {
            this.dropdownIsOpen = !this.dropdownIsOpen;

            if (this.dropdownIsOpen) {
                this.$nextTick(() => {
                    if (this.hasSearch && this.$refs.searchInput) {
                        this.$refs.searchInput.$el.querySelector('input').focus();
                    }
                });
            }
        },
        closeDropdown(e) {
            if (this.$refs.dropdownItems?.contains?.(e?.target)) {
                return;
            }

            this.dropdownIsOpen = false;
        },
        handleClear(e) {
            if (e) e.stopPropagation();
            this.$emit('onItemSelect', { item: null });
            this.internalSelected = null;
        },
        handleItemClick(item) {
            // Emit the event instead of changing the internal state directly
            this.$emit('onItemSelect', { item });
            this.internalSelected = item;

            // Close the dropdown
            this.closeDropdown();
            this.calculateDropdownWidth();
        },
        async setColumnCount(columns = 3) {
            if (columns === 0) {
                return;
            }
            this.dropdownStyle = {
                gridTemplateColumns: `repeat(${columns}, 1fr)`,
            };

            return new Promise(resolve => {
                setTimeout(() => {
                    const dropdownItems = this.$refs.dropdownItems;
                    if (!dropdownItems) {
                        resolve();
                        return;
                    }

                    const { right } = dropdownItems.getBoundingClientRect() || {};
                    const isRight = right > window.innerWidth;
                    if (isRight) {
                        this.setColumnCount(columns - 1);
                    }
                    resolve();
                }, 200);
            });
        },
    },
    beforeDestroy() {
        // Remove event listeners
        document.removeEventListener('keydown', this.handleKeyDown);

        // Clean up dropdown from DOM
        this.filteredItems = this.items;
        this.dropdownIsOpen = false;
    },
    mounted() {
        this.$nextTick(() => {
            this.calculateDropdownWidth();
            this.filteredItems = this.items;
            if (this.selected) {
                this.internalSelected = this.selected;
            }
        });
    },
};
</script>

<style lang="scss">
.Dropdown {
    font-weight: 700;
    color: var(--color-text-black);
    transition: all 0.3s ease-out;
    font-size: inherit;
    cursor: pointer;
    position: relative;
    user-select: none;
    perspective: 800px;
    z-index: 99;
    padding-right: 10px;
    background: rgba(0, 0, 0, 0.01);
    display: inline-block;
    border: solid 1px var(--color-text-inactive);
    border-radius: 5px;
    perspective-origin: 100% 0%;
    outline: none;

    &:focus-visible {
        box-shadow: 0 0 0 2px var(--color-blue);
        border-color: var(--color-blue);
    }

    > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 100%;
        justify-content: center;
    }

    &:hover {
        background-color: rgba(0, 0, 0, 0.06);
        border-color: rgba(0, 0, 0, 0.3);
    }

    &.has-error {
        border-color: var(--color-red, red);
        color: var(--color-red, red);
    }

    &.is-small {
        .Selected {
            padding: 4px 6px;
            font-size: 14px;
        }
    }

    &.is-open {
        z-index: 1000;
        border-color: var(--color-blue);
        box-shadow: 0 0 0 2px rgba(var(--color-blue-rgb), 0.2);

        .Icon-Dropdown {
            polyline {
                transform: rotateZ(180deg);
                transform-origin: center;
            }
        }
    }

    .Icon--Cross {
        cursor: pointer;
        margin: 5px;
        padding: 6px;
        box-sizing: content-box;
        position: relative;
        z-index: 2;
        border-radius: 4px;
        transition: background-color 0.2s ease;

        &:hover,
        &:focus {
            background-color: rgba(0, 0, 0, 0.1);
        }

        &:focus-visible {
            outline: 2px solid var(--color-blue);
            outline-offset: 2px;
        }
    }
}

.Dropdown-Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.35);
    z-index: 999;
    transition: all 0.3s ease-out;
    opacity: 0;
    visibility: hidden;

    &.is-open {
        opacity: 1;
        visibility: visible;
    }
}

.Dropdown-Wrapper {
    position: relative;
    z-index: 999;
}

.Dropdown-Items {
    opacity: 0;
    max-height: 0;
    visibility: hidden;
    background-color: var(--color-white);
    border-radius: 8px;
    border: solid 1px var(--color-text-inactive);
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.25);
    padding: 10px 0;
    position: absolute;
    top: 40px;
    left: 0;
    z-index: 10000;
    transform: translateX(-25px) translateZ(-50px) rotateX(-20deg);
    transform-origin: top;
    will-change: transform;
    min-width: 200px;
    transition: opacity 0.2s ease, transform 0.3s ease, visibility 0.2s;

    .Dropdown-Item {
        display: block;
        visibility: hidden;
        opacity: 0;
        color: var(--color-text-black);
        font-size: 0.875rem;
        padding: 8px 16px;
        border: solid 1px rgba(0, 0, 0, 0.05);
        flex: 1 0 34%;
        line-height: 1.25;
        min-width: max-content;
        cursor: pointer;
        font-weight: 500;
        transition: background-color 0.15s ease, opacity 0.2s ease;
        border-radius: 4px;
        margin: 2px 6px;
        outline: none;

        &:last-child {
            margin-bottom: 0;
        }

        &.is-selected {
            background-color: rgba(var(--color-blue-rgb), 0.1);
            color: var(--color-blue);
            font-weight: 600;
        }

        &.is-focused {
            background-color: rgba(0, 0, 0, 0.05);
        }

        &:hover {
            background-color: rgba(0, 0, 0, 0.05);
        }

        &:focus-visible {
            box-shadow: 0 0 0 2px var(--color-blue);
            background-color: rgba(var(--color-blue-rgb), 0.05);
        }
    }

    &.is-open {
        opacity: 1;
        visibility: visible;
        max-height: 700px;
        transform: translateX(-25px) translateZ(0px) rotateX(0deg);

        &.is-left {
            transform: translateX(calc(-100% + 45px)) translateZ(0px) rotateX(0deg);
        }

        .Dropdown-Item {
            opacity: 1;
            visibility: visible;
        }
    }

    &::before {
        position: absolute;
        content: '';
        display: block;
        top: -10px;
        left: 25px;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 15px solid var(--color-white);
        filter: drop-shadow(0 -2px 2px rgba(0, 0, 0, 0.1));
    }

    &.is-left {
        transform: translateX(calc(-100% + 45px)) translateZ(-50px) rotateX(-20deg);

        &::before {
            left: unset;
            right: 25px;
        }
    }

    .Dropdown-Items-Scroll-Wrapper {
        max-height: 320px;
        overflow-y: auto;
        display: grid;
        scrollbar-width: thin;
        scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

        &::-webkit-scrollbar {
            width: 6px;
            height: 6px;
        }

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 6px;
        }
    }
}

.Selected {
    padding: 6px 12px;
    transition: all 0.3s;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;

    &.not-selected {
        color: var(--color-gray);
        font-weight: normal;
        font-style: italic;
    }
}

.Icon-Dropdown {
    display: block;

    polyline {
        transition: all 0.2s;
        transform-origin: center;
    }
}

.Search {
    border: solid 1px var(--color-text-inactive);
    border-radius: 4px;
    margin: 0px 10px 10px;
    display: block;
}
</style>
